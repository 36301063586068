import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import withRouter from "react-router/es/withRouter";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import { refreshToken } from "./store/actions/authActions";
import { history } from "./helpers/history";
import ROUTES from "./constants/routes";
import NotAuthorizedLayout from "./components/Layouts/NotAuthorizedLayout";
import AuthorizedLayout from "./components/Layouts/AuthorizedLayout";
import ROLES from "./constants/roles";
import Page404 from "./components/Page404/Page404";
import SeasonPass from "./containers/SeasonPass/SeasonPass";
import SeasonPassMember from "./containers/SeasonPass/SeasonPassMember/SeasonPassMember";

import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./style/App.sass";
import TestIframe from "./containers/TestIframe";
import ResellingRequest from "./containers/ResellingRequest/ResellingRequest";
import SellingEventsList from "./containers/SellingEventsList/SellingEventsList";
import SatsScan from "./containers/SatsScan/SatsScan";
import SatsSells from "./containers/SatsSells/SatsSells";
import SeasonPassPersonalInfo from "./containers/SeasonPass/SeasonPassPersonalInfo/SeasonPassPersonalInfo";
import OpenSell from "./containers/OpenSell/OpenSell";
import { getRegion } from "./helpers/getRegion";
import { addHeadTags } from "./helpers/addHeadTags";
import ls from 'localstorage-slim';
import moment from "moment";
import MemberPage from "./containers/SeasonPass/SeasonPassPersonalInfo/MemberPage";

class App extends Component {
  componentDidMount() {
    if (ls.get("user")) {
      const data = {
        refreshToken: this.props.auth.user.refreshToken,
      };
      this.props.refreshToken(data);
    }

    const body = document.getElementById("body");
    const { url, theme, title } = getRegion();

    addHeadTags({
      url,
      folder: theme,
      title,
    })

    body.dataset.theme = theme;
    moment.locale('en');
  }

  render() {
    const { loading, user } = this.props.auth;

    if (loading) {
      return null;
    }

    let routes = null;

    if (user.role === ROLES.PROMOTER) {
      routes = (
        <Fragment>
          <Switch>
            <Route path={ROUTES.FAQ} component={AuthorizedLayout} />
            <Route path={ROUTES.EVENTS} component={AuthorizedLayout} />

            <Route path={ROUTES.NEW_ADD_EVENT} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_EVENT} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_EVENT_INFO} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_EVENT_TICKETS} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_EVENT_PDF} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_EVENT_EMAIL} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_EVENT_WALLETS} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_EVENT_TRANSACTIONS} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_EVENT_SELL} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_EVENT_SEATS} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_EVENT_REPORTS} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_EVENT_STATISTICS} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_EVENT_RESELLING} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_EVENT_MANAGEMENT} exact component={AuthorizedLayout} />

            <Route path={ROUTES.NEW_TICKET_DETAIL} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_ADD_TICKET} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_ADD_BAR_CODE} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_GENERATE_PDFS} exact component={AuthorizedLayout} />
            <Route path={ROUTES.NEW_SEND_BUY_EMAIL} exact component={AuthorizedLayout} />

            <Route path={ROUTES.SCANTEAMS} exact component={AuthorizedLayout} />
            <Route
              path={ROUTES.SCANTEAMS_ADD}
              exact
              component={AuthorizedLayout}
            />
            <Route
              path={ROUTES.SCANTEAMS_VIEW}
              exact
              component={AuthorizedLayout}
            />
            <Route path={ROUTES.TICKET_DETAIL} component={AuthorizedLayout} />
            <Route
              path={ROUTES.ADD_TICKET}
              exact
              component={AuthorizedLayout}
            />
            <Route
              path={ROUTES.ADD_TICKET}
              exact
              component={AuthorizedLayout}
            />
            <Route
              path={ROUTES.MEMBERSHIP_MANAGMENT}
              exact
              component={AuthorizedLayout}
            />

            <Route path={ROUTES.PROMOTERS} exact component={AuthorizedLayout} />
            <Route path={ROUTES.PROMOTER} exact component={AuthorizedLayout} />
            <Route path={ROUTES.PROMOTER_INFO} exact component={AuthorizedLayout} />
            <Route path={ROUTES.PROMOTER_TEMPLATES} exact component={AuthorizedLayout} />
            <Route path={ROUTES.PROMOTER_BALANCE} exact component={AuthorizedLayout} />

            <Route path={ROUTES.PRODUCT_DEFINITIONS} exact component={AuthorizedLayout} />
            <Route path={ROUTES.PRODUCT_DEFINITION_DETAIL} exact component={AuthorizedLayout} />
            
            {/* <Route path={ROUTES.DASHBOARD} component={AuthorizedLayout} /> */}
            <Route path={ROUTES.STATISTICS} component={AuthorizedLayout} />
            <Route path={ROUTES.STATS_SCANNING} component={AuthorizedLayout} />
            <Route path={ROUTES.STATS_SELLING} component={AuthorizedLayout} />
            <Route path={ROUTES.EMAILS} component={AuthorizedLayout} />

            <Redirect from="/" exact to={ROUTES.EVENTS} />

            <Route path="/" component={Page404} />
          </Switch>
        </Fragment>
      );
    }

    if (user.role === ROLES.ADMIN) {
      routes = (
        <Switch>
          <Route path={ROUTES.PROMOTERS} component={AuthorizedLayout} />

          <Redirect from="/" exact to={ROUTES.PROMOTERS} />

          <Route path="/" component={Page404} />
        </Switch>
      );
    }

    return (
      <Fragment>
        <ToastContainer
          position="top-center"
          transition={Slide}
          autoClose={5000}
          pauseOnHover={false}
          closeButton={false}
          closeOnClick={false}
          hideProgressBar
          draggable
        />
        <Router history={history}>
          <Switch>
            <Route path="/" component={NotAuthorizedLayout} exact />
            <Route path={ROUTES.REGISTRATION} exact component={NotAuthorizedLayout} />
            <Route
              path={ROUTES.REGISTRATION_SUCCESS}
              component={NotAuthorizedLayout}
              exact
            />
            <Route path={ROUTES.LOGIN} exact component={NotAuthorizedLayout} />
            <Route
              path={ROUTES.RECOVER_PASSWORD}
              component={NotAuthorizedLayout}
              exact
            />
            <Route path={ROUTES.NEW_PASSWORD} exact component={NotAuthorizedLayout} />
            <Route path={ROUTES.SEASON_PASS} component={SeasonPass} exact />
            <Route
              path={ROUTES.EVENTS_LIST_TO_SELLING}
              component={SellingEventsList}
              exact
            />
            <Route path={ROUTES.SELLING} component={OpenSell} exact />
            <Route
              path={ROUTES.SELLING_OFFERS}
              component={OpenSell}
              exact
            />
            <Route
              path={ROUTES.SEASON_PASS_MEMBER}
              component={SeasonPassMember}
              exact
            />
            <Route path={ROUTES.MEMBER} component={SeasonPassPersonalInfo} exact />
            <Route path={ROUTES.MEMBER_NEW} component={MemberPage} exact />
            <Route path={ROUTES.MEMBER_NEW_INFO} component={MemberPage} exact />
            <Route path={ROUTES.MEMBER_NEW_CARDS} component={MemberPage} exact />
            <Route path={ROUTES.MEMBER_NEW_EVENTS} component={MemberPage} exact />
            <Route path={"/test-iframe"} component={TestIframe} exact />
            <Route
              path={ROUTES.RESELLING_REQUEST}
              component={ResellingRequest}
              exact
            />
            {/* {!user.role && (
              <Switch>
                <Route
                  path={ROUTES.STATS_SCANNING}
                  exact
                  component={SatsScan}
                />
                <Route
                  path={ROUTES.STATS_SELLING}
                  exact
                  component={SatsSells}
                />
              </Switch>
            )} */}
            {routes}
            <Route path="/" component={NotAuthorizedLayout} />
          </Switch>
        </Router>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = (dispatch) => ({
  refreshToken: (data) => {
    dispatch(refreshToken(data));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
