import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import Select from 'react-select';
import swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import NewLoader from '../../components/NewLoader/NewLoader';
import PageTitle from '../../components/UI/PageTitle/PageTitle';
import Container from '../../components/UI/Container/Container';
import {
  getEmails,
  getEmail,
  getProblematicEmailsCount,
  doBulkAction,
  resendEmail,
} from '../../store/actions/emailsActions';
import { EmailModal } from './EmailModal/EmailModal';
import CheckBox from '../../components/UI/Checkbox/CheckBox';
import { Tooltip } from '../../components/UI/Tooltip/Tooltip';
import { BulkAction } from './BulkActions';
import { bulkActions, emailsStatuses, emailsTypes, periods } from '../../constants/emails';

import '../MembershipManagment/MembershipManagment.sass';
import './Emails.sass';

const MySwal = withReactContent(swal);

const Emails = ({
  getEmails,
  isEmailsLoading,
  getEmail,
  resendEmail,
  getProblematicEmailsCount,
  problematicEmailsCount,
  doBulkAction
}) => {
  const [data, setData] = useState([]);
  const [searchPeriod, setSearchPeriod] = useState(periods[0]);
  const [searchStatus, setSearchStatus] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [requestedQuery, setRequestedQuery] = useState('');
  const [emailType, setEmailType] = useState(null);
  const [isShowProblematic, setShowProblematic] = useState(false);
  const [isSelectAll, setSelectAll] = useState(false);
  const [isChangeRow, setChangeRow] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(500);
  const [dataToDisplay, setDataToDisplay] = useState([]);

  const ReactTableFixedColumns = useMemo(
    () => withFixedColumns(ReactTable),
    []
  );

  const getData = async () => {
    let commonType = 0;

    if (isShowProblematic) {
      commonType = 1;
    }

    const emails = await getEmails({
      period: searchPeriod ? searchPeriod.value : null,
      status: searchStatus ? searchStatus.label : null,
      search: requestedQuery,
      type: emailType ? emailType.value : commonType,
    });

    if (emailType && emailType.value === 3) {
      const problematicEmails = emails.filter((e) => e.isProblematic);
      setData(problematicEmails);

      return;
    }

    setData(emails);
  };

  const resendEmailHandler = (id, email) => {
    resendEmail(id, email).then(() => {
      getData();
    });
  };

  const showResendModal = async (id, address) => {
    const { value: email } = await MySwal.fire({
      title: 'Resend email',
      text: 'Are you sure you want to resend email?',
      input: 'email',
      inputValue: address,
      inputLabel: 'Email address',
      inputPlaceholder: 'Email address',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Resend',
      allowEscapeKey: false,
      customClass: 'swal2-modal',
      allowEnterKey: false,
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
    });

    if (email) {
      resendEmailHandler(id, email);
    }
  };

  const showEmailModal = (emailId, errorMessage, address) => {
    MySwal.fire({
      html: (
        <div className='emails__modal-loader'>
          <NewLoader />
        </div>
      ),
      width: '80vw',
      customClass: 'seasonpass__modal swal2-modal',
      showConfirmButton: false,
    });

    getEmail(emailId).then((email) => {
      MySwal.fire({
        html: <EmailModal email={email} errorMessage={errorMessage} />,
        width: '80vw',
        customClass: 'swal2-modal',
        showConfirmButton: true,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'Resend',
      }).then((result) => {
        if (result.value) {
          showResendModal(emailId, address);
        }
      });
    });
  };

  const changeSearchStatusHandler = (item) => {
    setSearchStatus(item);
  };
  const changeSearchPeriodHandler = (item) => {
    setSearchPeriod(item);
  };

  const handleDebonceFn = (value) => {
    setRequestedQuery(value);
  };

  const debounceFn = useCallback(debounce(handleDebonceFn, 1500), []);

  const changeSearchInputHandler = (e) => {
    setSearchQuery(e.target.value);
    debounceFn(e.target.value);
  };

  const changeEmailTypeHandler = (item) => {
    setEmailType(item);
  };

  const showProblematicHandler = () => {
    setShowProblematic(!isShowProblematic);
  };

  const handleBulkAction = (actionId) => {
    doBulkAction(actionId, selectedIds)
      .then(() => {
        getData();
        setSelectAll(false);
        setChangeRow(false);
        setSelectedIds([]);
      })
  };

  const handleSelectAll = () => {
    setSelectAll(!isSelectAll);
    setChangeRow(false);
  };

  const toggleRow = (rowId, idsList) => {
    setSelectAll(false);
    setChangeRow(true);
    const targetId = idsList.some((currId) => currId === rowId);

    let newArray = [];

    if (targetId) {
      newArray = idsList.filter((currId) => currId !== rowId);
    } else {
      newArray = [...idsList, rowId];
    }

    setSelectedIds(newArray);

    setTableColumns((prev) => {
      return prev.map((column, idx) => {
        if (idx === 0) {
          return {
            ...column,
            Cell: ({
              row: {
                _original: { id },
              },
            }) => {
              const targetId = newArray.some((currId) => currId === id);
              return (
                <CheckBox
                  id={`is-selected-${id}`}
                  checked={targetId}
                  onChange={() => toggleRow(id, newArray)}
                ></CheckBox>
              );
            },
          };
        }

        return column;
      });
    });
  };

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      height: '46px',
      'min-height': '46px',
      cursor: 'pointer',
    }),
    placeholder: (base) => ({
      ...base,
      color: '#ABABAB',
    }),
  };

  const handleCurrentPageChanged = (value) => {
    setCurrentPageNumber(value);
  };

  const handlePageSizeChanged = (value) => {
    setPageSize(value);
  };

  const goToCurrentPage = () => {
    const start = currentPageNumber * pageSize;
    const end = (currentPageNumber + 1) * pageSize;

    setDataToDisplay(data.slice(start, end));
  };

  const refreshDataToDisplay = () => {
    if (currentPageNumber === 0) {
      goToCurrentPage();
    } else {
      setCurrentPageNumber(0);
    }
  };

  useEffect(() => {
    refreshDataToDisplay();
  }, [data, pageSize]);

  useEffect(() => {
    goToCurrentPage();
  }, [currentPageNumber]);

  useEffect(() => {
    getData();
    setSelectAll(false);
    setChangeRow(false);
  }, [
    requestedQuery,
    searchStatus,
    searchPeriod,
    emailType,
    isShowProblematic,
  ]);

  useEffect(() => {
    getProblematicEmailsCount();
  }, [data]);

  let emptyStateText = 'There are no data';

  const getLabelStyle = (state, rowInfo, column) => ({
    style: {
      marginTop: '6px',
    },
  });

  const getCorrectedStyle = (state, rowInfo, column) => ({
    style: {
      marginTop: '3px',
    },
  });

  const getStatusStyle = () => ({
    style: {
      marginTop: '3px',
      padding: '0 20px',
      display: 'flex',
      alignItems: 'center',
    },
  });
  const getCheckboxStyle = () => ({
    style: {
      marginTop: '6px',
      padding: '0 20px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'default',
    },
    onClick: (e) => {
      e.stopPropagation();
    },
  });

  const columns = [
    {
      Header: () => (
        <CheckBox
          id='is-select-all'
          checked={isSelectAll}
          onChange={handleSelectAll}
        ></CheckBox>
      ),
      getProps: getCheckboxStyle,
      width: 70,
      resizable: false,
      Cell: ({
        row: {
          _original: { id },
        },
      }) => (
        <CheckBox
          id={`is-selected-${id}`}
          checked={false}
          onChange={() => toggleRow(id, selectedIds)}
        ></CheckBox>
      ),
    },
    {
      Header: 'Email',
      accessor: 'toAddress',
      resizable: false,
      getProps: getCorrectedStyle,
      Cell: ({
        row: {
          _original: { toAddress },
        },
      }) => <span title={toAddress}>{toAddress}</span>,
    },
    {
      Header: 'Subject',
      accessor: 'subject',
      resizable: false,
      getProps: getLabelStyle,
      Cell: ({
        row: {
          _original: { subject },
        },
      }) => <span title={subject}>{subject}</span>,
    },
    {
      Header: 'Status',
      resizable: false,
      getProps: getStatusStyle,
      width: 150,
      Cell: ({
        row: {
          _original: { status, errorMessage },
        },
      }) => {
        const isGreenStatus =
          status === 'delivered' || status === 'open' || status === 'click';

        const isYellowStatus = status === 'bounce' || status === 'processed';
        if (isGreenStatus)
          return (
            <div className={`status-label status-label--${status}`}>
              {status}
            </div>
          );

        if (isYellowStatus)
          return (
            <Tooltip
              className={`status-label status-label--${status}`}
              description={errorMessage}
            >
              {status}
            </Tooltip>
          );

        return (
          <Tooltip
            className={`status-label status-label--other`}
            description={errorMessage}
          >
            {`${status}`}
          </Tooltip>
        );
      },
    },
    {
      Header: 'Date&Time (UTC)',
      resizable: false,
      width: 200,
      getProps: getLabelStyle,
      Cell: ({ row: { _original: data } }) => {
        const date = new Date(data.modifiedAtUtc);
        return (
          <>
            {date.toLocaleDateString()} {date.toLocaleTimeString()}
          </>
        );
      },
    },
    {
      Header: 'Problem',
      resizable: false,
      getProps: getStatusStyle,
      width: 150,
      Cell: ({
        row: {
          _original: { isSolved, isProblematic },
        },
      }) => {
        if (isProblematic) {
          if (isSolved) {
            return (
              <div className={`status-label status-label--delivered`}>
                Solved
              </div>
            );
          } else {
            return (
              <div className={`status-label status-label--other`}>
                Unsolved
              </div>
            );
          }
        }

        return null
      },
    },
  ];

  const [tabelColumns, setTableColumns] = useState(columns);

  useEffect(() => {
    setTableColumns((prev) => {
      return prev.map((column, idx) => {
        if (idx === 0) {
          return {
            ...column,
            Header: () => (
              <CheckBox
                id='is-select-all'
                checked={isSelectAll}
                onChange={handleSelectAll}
              ></CheckBox>
            ),
          };
        }

        return column;
      });
    });

    if (isSelectAll) {
      const ids = data.map((item) => item.id);
      setSelectedIds(ids);

      setTableColumns((prev) => {
        return prev.map((column, idx) => {
          if (idx === 0) {
            return {
              ...column,
              Cell: ({
                row: {
                  _original: { id },
                },
              }) => {
                const targetId = ids.some((currId) => currId === id);
                return (
                  <CheckBox
                    id={`is-selected-${id}`}
                    checked={targetId}
                    onChange={() => toggleRow(id, ids)}
                  ></CheckBox>
                );
              },
            };
          }

          return column;
        });
      });

      return;
    }

    if (!isSelectAll && !isChangeRow) {
      setSelectedIds([]);

      setTableColumns((prev) => {
        return prev.map((column, idx) => {
          if (idx === 0) {
            return {
              ...column,
              Cell: ({
                row: {
                  _original: { id },
                },
              }) => {
                return (
                  <CheckBox
                    id={`is-selected-${id}`}
                    checked={false}
                    onChange={() => toggleRow(id, [])}
                  ></CheckBox>
                );
              },
            };
          }

          return column;
        });
      });
    }
  }, [isSelectAll, data]);

  console.log(data.length, isEmailsLoading);

  return (
    <Container className='emails membership-managment'>
      <di className='emails__header'>
        <PageTitle>Emails</PageTitle>
        {problematicEmailsCount && (
          <div>
            <strong>Unsolved emails:</strong> {problematicEmailsCount}
          </div>
        )}
      </di>

      <div className=' searchPanel emails__searchPanel'>
        <Select
          options={periods}
          placeholder='Select period'
          name='searchProfilePeriod'
          classNamePrefix='custom-select'
          value={searchPeriod}
          onChange={changeSearchPeriodHandler}
          className='seasonpass__select searchPanel__select'
          styles={customSelectStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#6071B5',
              primary25: '#F2F4FE',
              primary50: '#F2F4FE',
              primary75: '#F2F4FE',
            },
          })}
        />

        <Select
          isClearable
          options={emailsStatuses}
          placeholder='Select email status'
          name='searchStatus'
          classNamePrefix='custom-select'
          value={searchStatus}
          onChange={changeSearchStatusHandler}
          className='seasonpass__select searchPanel__select emails__status'
          styles={customSelectStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#6071B5',
              primary25: '#F2F4FE',
              primary50: '#F2F4FE',
              primary75: '#F2F4FE',
            },
          })}
        />

        <input
          className='searchPanel__search input-behavior search-icon'
          type='text'
          placeholder='Search by email'
          value={searchQuery}
          onChange={changeSearchInputHandler}
        />
      </div>

      <div className='emails__bulk-change'>
        <Select
          isClearable
          options={emailsTypes}
          placeholder='Select problem status'
          name='searchType'
          classNamePrefix='custom-select'
          value={emailType}
          onChange={changeEmailTypeHandler}
          className='seasonpass__select searchPanel__select emails__status'
          styles={customSelectStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#6071B5',
              primary25: '#F2F4FE',
              primary50: '#F2F4FE',
              primary75: '#F2F4FE',
            },
          })}
        />

        <CheckBox
          name='isProblematick'
          id='is-problematic'
          checked={isShowProblematic}
          onChange={showProblematicHandler}
        >
          Show only problematic emails
        </CheckBox>

        {selectedIds.length > 0 && (
          <BulkAction
            resend={() => handleBulkAction(bulkActions.resend)}
            solve={() => handleBulkAction(bulkActions.solve)}
            unsolve={() => handleBulkAction(bulkActions.unsolve)}
          />
        )}
      </div>

      {isEmailsLoading ? (
        <NewLoader />
      ) : data.length ? (
        <>
          <ReactTableFixedColumns
            data={dataToDisplay}
            columns={tabelColumns}
            manual
            showPagination={true}
            pageSizeOptions={[100, 200, 500, 1000, 100000]}
            minRows={0}
            page={currentPageNumber}
            pageSize={pageSize}
            pages={Math.ceil(data.length / pageSize)}
            onPageChange={handleCurrentPageChanged}
            onPageSizeChange={handlePageSizeChanged}
            sortable={false}
            getTrProps={(state, { original }) => ({
              style: {
                alignItems: 'stretch',
              },
              onClick: () => {
                showEmailModal(
                  original.id,
                  original.errorMessage,
                  original.toAddress
                );
              },
            })}
            // getTableProps={() => ({
            //   onScroll: handleTransactionsTableScroll,
            // })}
          />
        </>
      ) : (
        <div className='events__empty-state'>
          <PageTitle>{emptyStateText}</PageTitle>
          <img src='/images/img-empty-events.svg' alt='Empty membership' />
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = ({ emails }) => ({
  emailsList: emails.emailsList,
  isEmailsLoading: emails.isEmailsLoading,
  currentEmail: emails.currentEmail,
  isEmailLoading: emails.isEmailLoading,
  isProblematicSolving: emails.isProblematicSolving,
  problematicEmailsCount: emails.problematicEmailsCount,
});

const mapDispatchToProps = (dispatch) => ({
  getEmails: (params) => dispatch(getEmails(params)),
  getEmail: (id) => dispatch(getEmail(id)),
  resendEmail: (id, newEmail) => dispatch(resendEmail(id, newEmail)),
  doBulkAction: (bulkAction, emailIds) => dispatch(doBulkAction(bulkAction, emailIds)),
  getProblematicEmailsCount: () => dispatch(getProblematicEmailsCount()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Emails));
