import React, { useState } from "react";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getCurrentLanguageTexts } from "../../../../constants/seasonPassPersonalInfoLangs";
import { FillCompanyDataModal } from "./FillCompanyDataModal";

export const BuyMembershipModal = ({ text, handleBuy }) => {
  const [buyer, setBuyer] = useState("individual");

  const MySwal = withReactContent(swal);
  const { buyMembershipTxt, dataFormTxt } = getCurrentLanguageTexts();

  const handleChangeOption = (e) => {
    setBuyer(e.target.value);
  }

  const handleConfirm = () => {
    if (buyer === "individual") {
      handleBuy()
    } else {
      MySwal.fire({
        title: dataFormTxt.companyDataTitle,
        html: <FillCompanyDataModal handleBuy={(companyInfo) => handleBuy(buyer, companyInfo)} />,
        showConfirmButton: false,
        customClass: "partizan-modal delete-user-modal",
      });
    }
  }

  return (
    <div className="buy-membership-modal">
      <p className="buy-membership-modal__title">{text}</p>

      <div className="buy-membership-modal__radio-wrapper">
        <label className="radiobutton">
          <input
            className="radiobutton__input"
            name="operation"
            type="radio"
            value="individual"
            onChange={handleChangeOption}
            checked={buyer === "individual"}
          />
          <span>{buyMembershipTxt.individualBuyer}</span>
        </label>
        <label className="radiobutton">
          <input
            className="radiobutton__input"
            name="operation"
            type="radio"
            value="company"
            onChange={handleChangeOption}
            checked={buyer === "company"}
          />
          <span>{buyMembershipTxt.companyBuyer}</span>
        </label>
      </div>

      <div className="swal2-actions">
        <button
          type='button'
          className='swal2-cancel swal2-styled'
          onClick={() => MySwal.close()}
        >
          {buyMembershipTxt.cancelBtn}
        </button>
        <button onClick={handleConfirm} className="swal2-confirm swal2-styled">
          {buyMembershipTxt.buyBtn}
        </button>
      </div>
    </div>
  )
}