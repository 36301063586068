import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { withRouter } from "react-router";
import ROUTES from "../../constants/routes";
import { getEvents } from "../../store/actions/eventsActions";

import Container from "../../components/UI/Container/Container";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import EventsMobileTable from "./EventsMobileTable";
import "react-table/react-table.css";
import moment from "moment";
import queryString from "query-string";
import NewLoader from "../../components/NewLoader/NewLoader";
import browserTabTitleOverride from "../../helpers/tabTitleOverride";
import debounce from "lodash.debounce";
import ls from 'localstorage-slim';
import Icon from "../../components/UI/Icon/Icon";

import "./Events.sass";

const sortTypes = {
  None: 1,
  NameAscending: 2,
  NameDescending: 3,
  StartDateAscending: 4,
  StartDateDescending: 5,
  EndDateAscending: 6,
  EndDateDescending: 7,
}

class Events extends Component {
  state = {
    searchEventValue: "",
    isPassedEvents: false,
    isNonexistentEvent: false,
    action: queryString.parse(this.props.location.search).action || '',
    sortingType: sortTypes.StartDateDescending,
  };

  componentDidMount() {
    browserTabTitleOverride();
    const user = ls.get("user");
    const isPassedEvents = ls.get("isPassedEvents");
    if (isPassedEvents) {
      this.setState({ isPassedEvents });
    }
    this.getEvents(user.token, isPassedEvents);
  }

  goToAddEventPage = () => {
    this.props.history.push(ROUTES.NEW_ADD_EVENT);
  };

  goToEventPage = (id) => {
    this.props.history.push({
      pathname: ROUTES.NEW_EVENT_INFO.replace(":id", id),
    });
  };

  getEvents = (userToken, isPassed, sortingType) => {
    const { isPassedEvents, searchEventValue, action } = this.state;
    const { getEvents } = this.props;

    getEvents(userToken, isPassed || isPassedEvents, searchEventValue, 0, 0, action, sortingType);
  };

  searchEvent = debounce((event) => {
    let { isPassedEvents, action, sortingType } = this.state;
    const { getEvents } = this.props;
    const user = ls.get("user");

    this.setState(
      { searchEventValue: event.target.value.trim().replace(/  +/g, " ") },
      () => {
        getEvents(
          user.token,
          isPassedEvents,
          this.state.searchEventValue,
          0,
          0,
          action,
          sortingType
        ).then((result) => {
          if (result && !result.length) {
            this.setState({ isNonexistentEvent: true });
          }
        });
      }
    );
  }, 450);

  isPassedEvents = (e) => {
    let { isPassedEvents, searchEventValue, action, sortingType } = this.state;
    const { getEvents } = this.props;
    const user = ls.get("user");
    ls.set("isPassedEvents", !isPassedEvents);
    this.setState({ isPassedEvents: !isPassedEvents }, () => {
      getEvents(user.token, this.state.isPassedEvents, searchEventValue, 0, 0, action, sortingType);
    });
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  changeSortingType = (type) => {
    const user = ls.get("user");
    const { isPassedEvents } = this.state;
    this.setState({ sortingType: type });

    this.getEvents(user.token, isPassedEvents, type)
  }

  render() {
    const { sortingType } = this.state;
    
    const columns = [
      {
        Header: "Event name",
        accessor: "name",
        resizable: false
      },
      {
        Header: "Scanned tickets",
        accessor: "scannedTickets",
        resizable: false,
        width: 160,
      },
      {
        Header: "All tickets",
        accessor: "allTickets",
        resizable: false,
        width: 160,
      },
      {
        Header: () => {
          return <div className="payment-status" onClick={() => this.changeSortingType(sortingType === sortTypes.StartDateAscending ? sortTypes.StartDateDescending : sortTypes.StartDateAscending)}>
            <span>Start Date&Time</span>
            <Icon name="arrow-up-down" />
          </div>
        },
        resizable: false,
        width: 200,
        Cell: ({ row: { _original: event } }) => {
          return <>{moment(event.startDate).format("DD MMMM YYYY HH:mm")}</>
        },
      },
      {
        Header: () => {
          return <div className="payment-status" onClick={() => this.changeSortingType(sortingType === sortTypes.EndDateAscending ? sortTypes.EndDateDescending : sortTypes.EndDateAscending)}>
            <span>End Date&Time</span>
            <Icon name="arrow-up-down" />
          </div>
        },
        resizable: false,
        width: 200,
        Cell: ({ row: { _original: event } }) => {
          return <>{moment(event.endDate).format("DD MMMM YYYY HH:mm")}</>
        }
      },
    ];
    const { eventsList, loading } = this.props.events;
    const { isPassedEvents } = this.state;
    let emptyStateText = "There are no events";

    if (this.state.isNonexistentEvent) {
      emptyStateText = "Sorry, no results were found";
    }

    return (
      <Container className="events">
        <PageTitle>Events</PageTitle>

        <input
          className="events__search search-icon"
          type="text"
          placeholder="Search"
          onChange={(e) => {
            e.persist();
            this.searchEvent(e);
          }}
        />

        <div className="checkbox">
          <input
            type="checkbox"
            id="passed-events"
            checked={isPassedEvents}
            onClick={this.isPassedEvents}
          />
          <label htmlFor="passed-events">Show past events</label>
          {loading ? (
            <NewLoader />
          ) : eventsList.length ? (
            <Fragment>
              <ReactTable
                data={eventsList}
                columns={columns}
                showPagination={false}
                minRows={0}
                sortable={false}
                defaultPageSize={eventsList.length}
                getTrProps={(state, rowInfo) => ({
                  onClick: (e) => {
                    this.goToEventPage(rowInfo.original.id, e);
                  },
                })}
              />
              <EventsMobileTable
                events={eventsList}
                goToEvent={this.goToEventPage}
              />
            </Fragment>
          ) : (
            <div className="events__empty-state">
              <PageTitle>{emptyStateText}</PageTitle>
              <img src="/images/img-empty-events.svg" alt="Empty events" />
            </div>
          )}
        </div>
        <div className="page-footer">
          <button
            type="button"
            className="btn-primary"
            onClick={this.goToAddEventPage}
          >
            Add event
          </button>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth, events }) => ({ auth, events });

const mapDispatchToProps = (dispatch) => ({
  getEvents: (userToken, isPassedEvents, searchEventValue, skip, take, action, sortType) =>
    dispatch(
      getEvents(userToken, isPassedEvents, searchEventValue, skip, take, action, sortType)
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Events));
