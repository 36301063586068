const ROUTES = {
  LOGIN: "/login",
  REGISTRATION: "/register",
  REGISTRATION_SUCCESS: "/register-success",
  RECOVER_PASSWORD: "/forgot-password",
  NEW_PASSWORD: "/new-password",
  EVENTS: "/my-events",
  FAQ: "/faq",
  
  SELLING: "/events/:slug/:id?",
  RESELLING_REQUEST: "/reselling-request/:eventId/old", // ???
  
  ADD_BAR_CODE: "/event/:event_id/old/ticket/:id/add-bar-code",
  GENERATE_PDFS: "/event/:event_id/old/ticket/:id/generate-pdfs",
  SEND_BUY_EMAIL: "/event/:event_id/old/ticket/:id/send-buy-email",
  TICKET_DETAIL: "/event/:event_id/old/ticket/:id",
  ADD_TICKET: "/ticket/add/:id/old",
  
  SCANTEAMS: "/scanteams",
  SCANTEAMS_ADD: "/scanteams/add",
  SCANTEAMS_VIEW: "/scanteam/:id",
  PROMOTERS: "/promoters",
  MEMBERSHIP_MANAGMENT: "/membership-managment",
  SEASON_PASS: "/season-pass/:id",
  EVENTS_LIST_TO_SELLING: "/events/list/:iframeSlug",
  SELLING_OFFERS: "/offers/:transactionId",
  SEASON_PASS_MEMBER: "/SeasonPassMember/:lang?",
  MEMBER: "/member",
  DASHBOARD: "/dashboard",
  TEST: "/test",
  STATISTICS: "/statistics",
  STATS_SCANNING: "/statistics/scanning",
  STATS_SELLING: "/statistics/selling",
  EMAILS: "/emails",

  MEMBER_NEW: "/member_new/:accessToken",
  MEMBER_NEW_INFO: "/member_new/:accessToken/info",
  MEMBER_NEW_CARDS: "/member_new/:accessToken/cards",
  MEMBER_NEW_EVENTS: "/member_new/:accessToken/events",

  NEW_ADD_EVENT: "/add-event",
  NEW_EVENT: "/event/:id",
  NEW_EVENT_INFO: "/event/:id/info",
  NEW_EVENT_TICKETS: "/event/:id/tickets",
  NEW_EVENT_PDF: "/event/:id/pdf",
  NEW_EVENT_EMAIL: "/event/:id/email",
  NEW_EVENT_WALLETS: "/event/:id/wallets",
  NEW_EVENT_TRANSACTIONS: "/event/:id/transactions",
  NEW_EVENT_SELL: "/event/:id/sell/:orderId?",
  NEW_EVENT_SEATS: "/event/:id/seats",
  NEW_EVENT_REPORTS: "/event/:id/reports",
  NEW_EVENT_STATISTICS: "/event/:id/statistics",
  NEW_EVENT_MANAGEMENT: "/event/:id/management",
  NEW_EVENT_RESELLING: "/event/:id/reselling",

  NEW_TICKET_DETAIL: "/event/:id/ticket/:ticket_id",
  NEW_ADD_BAR_CODE: "/event/:id/ticket/:ticket_id/add-bar-code",
  NEW_ADD_TICKET: "/event/:id/ticket/add",
  NEW_GENERATE_PDFS: "/event/:id/ticket/:ticket_id/generate-pdfs",
  NEW_SEND_BUY_EMAIL: "/event/:id/ticket/:ticket_id/send-buy-email",

  PROMOTER: "/promoter",
  PROMOTER_INFO: "/promoter/info",
  PROMOTER_TEMPLATES: "/promoter/templates",
  PROMOTER_BALANCE: "/promoter/balance",

  PRODUCT_DEFINITIONS: "/products",
  PRODUCT_DEFINITION_DETAIL: "/products/:id",
};

export default ROUTES;
