import React, { useState } from "react";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Select from "react-select";
import { getCurrentLanguageTexts } from "../../../../constants/seasonPassPersonalInfoLangs";

export const FillCompanyDataModal = ({ handleBuy }) => {
  const MySwal = withReactContent(swal);
  const { buyMembershipTxt, dataFormTxt, BuyAllSeasonPassesModalTxt, BuySeasonPassModalTxt, sellPageFieldsTxt } = getCurrentLanguageTexts();

  const [companyInfo, setCompanyInfo] = useState({
    companyName: '',
    companyCity: '',
    companyAddress: '',
    companyVatNumber: '',
    companyCountry: BuySeasonPassModalTxt.companyCountryOptions[0].value,
  });

  const handleDataChange = (key, value) => {
    setCompanyInfo(prev => ({
      ...prev,
      [key]: value,
    }))
  };

  const changeCompanyCountryHandler = (e) => {
    setCompanyInfo(prev => ({
      ...prev,
      companyCountry: e.value,
    }))
  }

  return (
    <div className="fill-company-data-modal">
      <div className="seasonpass-pers-info__block">
        <label>{BuyAllSeasonPassesModalTxt.companyNameLabel}</label>
        <input
          type="text"
          name="companyName"
          placeholder={BuyAllSeasonPassesModalTxt.companyNamePlaceholder}
          value={companyInfo.companyName}
          onChange={({ target }) => handleDataChange(target.name, target.value)}
        />
      </div>

      <div className="seasonpass-pers-info__block">
        <label>{sellPageFieldsTxt["Country"]}</label>
        <Select
          options={BuySeasonPassModalTxt.companyCountryOptions}
          name="companyCountry"
          value={BuySeasonPassModalTxt.companyCountryOptions.find(
              ({ value }) => companyInfo.companyCountry === value
          )}
          onChange={changeCompanyCountryHandler}
          classNamePrefix="seasonpass__select partizan-open-selling__select"
          className="seasonpass__select partizan-open-selling__select"
        />
      </div>

      <div className="seasonpass-pers-info__block">
        <label>{dataFormTxt.city}</label>
        <input
          type="text"
          name="companyCity"
          placeholder={BuyAllSeasonPassesModalTxt.companyCityPlaceholder}
          value={companyInfo.companyCity}
          onChange={({ target }) => handleDataChange(target.name, target.value)}
        />
      </div>

      <div className="seasonpass-pers-info__block">
        <label>{BuyAllSeasonPassesModalTxt.companyAddressLabel}</label>
        <input
          type="text"
          name="companyAddress"
          placeholder={BuyAllSeasonPassesModalTxt.companyAddressPlaceholder}
          value={companyInfo.companyAddress}
          onChange={({ target }) => handleDataChange(target.name, target.value)}
        />
      </div>

      <div className="seasonpass-pers-info__block">
        <label>{BuyAllSeasonPassesModalTxt.companyVatLabel}</label>
        <input
          type="text"
          name="companyVatNumber"
          placeholder={BuyAllSeasonPassesModalTxt.companyVatPlaceholder}
          value={companyInfo.companyVatNumber}
          onChange={({ target }) => handleDataChange(target.name, target.value)}
        />
      </div>

      <div className="swal2-actions">
        <button
          type='button'
          className='swal2-cancel swal2-styled'
          onClick={() => MySwal.close()}
        >
          {buyMembershipTxt.cancelBtn}
        </button>
        <button
          onClick={() => handleBuy(companyInfo)}
          className="swal2-confirm swal2-styled"
          disabled={
            !companyInfo.companyName ||
            !companyInfo.companyCity ||
            !companyInfo.companyAddress ||
            !companyInfo.companyVatNumber ||
            !companyInfo.companyCountry
          }
        >
          {buyMembershipTxt.buyBtn}
        </button>
      </div>
    </div>
  )
}