import * as actionTypes from "../actionTypes/emailsTypes";
import { toast } from "react-toastify";
import ls from 'localstorage-slim';
import axios from "../../axios-instance";
import { getHeaders } from "../../helpers/getHeaders";

//------------------ Get Emails ------------------- 

const getEmaisStart = () => ({ type: actionTypes.GET_EMAILS });

const getEmailsSuccess = (emails) => ({
  type: actionTypes.GET_EMAILS_SUCCESS,
  payload: emails,
});

const getEmailsFail = () => ({
  type: actionTypes.GET_EMAILS_FAIL,
});

export const getEmails = ({
  period = 0,
  skip = 0,
  take = 0,
  type = 0,
  search = "",
  status = "",
}) => async (dispatch) => {
  dispatch(getEmaisStart());

  const user = ls.get("user");

  return axios
    .get(`/Email`, {
      headers: getHeaders(user.token),
      params: { period, skip, take, type, search, status },
    })
    .then((response) => {
      if (response.data.isSuccess) {
        dispatch(getEmailsSuccess(response.data.result));

        return response.data.result;
      };

      throw new Error(response.data.errorMessage);
    })
    .catch((err) => {
      dispatch(getEmailsFail());

      toast.error("Something went wrong")
    });
};

const getEmaiStart = () => ({ type: actionTypes.GET_EMAIL });

const getEmailSuccess = (email) => ({
  type: actionTypes.GET_EMAIL_SUCCESS,
  payload: email,
});

const getEmailFail = () => ({
  type: actionTypes.GET_EMAIL_FAIL,
});

export const getEmail = (id) => (dispatch) => {
  dispatch(getEmaiStart());

  const user = ls.get("user");

  return axios
    .get(`/Email/${id}`, {
      headers: getHeaders(user.token),
    })
    .then((response) => {
      if (response.data.isSuccess) {
        dispatch(getEmailSuccess(response.data.result));

        return response.data.result;
      };

      throw new Error(response.data.errorMessage);
    })
    .catch((err) => {
      dispatch(getEmailFail());

      toast.error("Something went wrong")
    });
};

const resendEmaiStart = () => ({ type: actionTypes.RESEND_EMAIL });

const resendEmailSuccess = () => ({
  type: actionTypes.RESEND_EMAIL_SUCCESS,
});

const resendEmailFail = () => ({
  type: actionTypes.RESEND_EMAIL_FAIL,
});

export const resendEmail = (id, newEmail) => (dispatch) => {
  dispatch(resendEmaiStart());

  const user = ls.get("user");

  return axios
    .post(`/Email/${id}/Resend`, null, {
      headers: getHeaders(user.token),
      params: { newEmail },
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(resendEmailSuccess());

        toast.success("Email resent");

        return
      };

      throw new Error(response.data.errorMessage);
    })
    .catch((err) => {
      dispatch(resendEmailFail());

      toast.error("Something went wrong")
    });
};

const getProblematicEmailsCountSuccess = (number) => ({
  type: actionTypes.GET_PROBLEMATIC_EMAILS_COUNT_SUCCESS,
  payload: number,
});

export const getProblematicEmailsCount = () => (dispatch) => {
  const user = ls.get("user");

  return axios
    .get(`/Email/ProblematicEmailsCount`, {
      headers: getHeaders(user.token),
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(getProblematicEmailsCountSuccess(response.data));

        return response.data.result;
      };

      throw new Error(response.data.errorMessage);
    })
    .catch((err) => {
      dispatch(getEmailFail());

      toast.error("Problematic emails count not received")
    });
};

const updateProblematicEmaiStart = () => ({ type: actionTypes.UPDATE_PROBLEMATIC_EMAIL });

const updateProblematicEmaiSuccess = () => ({
  type: actionTypes.UPDATE_PROBLEMATIC_EMAIL_SUCCESS,
});

const updateProblematicEmaiFail = () => ({
  type: actionTypes.UPDATE_PROBLEMATIC_EMAIL_FAIL,
});

export const updateProblematicEmail = (id, isSolved) => (dispatch) => {
  dispatch(updateProblematicEmaiStart());

  const user = ls.get("user");

  return axios
    .patch(`/Email/${id}/UpdateProblematicEmailSolution?isSolved=${isSolved}`, null, {
      headers: getHeaders(user.token),
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(updateProblematicEmaiSuccess());

        toast.success("Email problematic updated");

        return
      };

      throw new Error(response.data.errorMessage);
    })
    .catch((err) => {
      dispatch(updateProblematicEmaiFail());

      toast.error("Email problematic not updated")
    });
}

export const doBulkAction = (bulkAction, emailIds) => (dispatch) => {
  const user = ls.get("user");
  dispatch(resendEmaiStart());

  return axios
    .post(`/Email/Bulk`,
      { bulkAction, emailIds },
      { headers: getHeaders(user.token) }
    )
    .then((response) => {
      if (response.status === 200) {
        dispatch(resendEmailSuccess());

        let actionName = '';

        switch (bulkAction) {
          case 1:
            actionName = 'resent'
            break;
          case 2:
            actionName = 'solved'
            break;
          case 3:
            actionName = 'unsolved'
            break;

          default:
            actionName = ''
            break;
        }

        const message = (emailIds.length > 1 ? `Emails ` : `Email `) + actionName

        toast.success(message);

        return
      };

      throw new Error(response.data.errorMessage);
    })
    .catch((err) => {
      dispatch(resendEmailFail());

      toast.error("Something went wrong")
    });
};
