import React, { useState } from 'react';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
import { IconButton } from '../../../../components/UI/IconButton/IconButton';
import { getCurrencieLabel } from '../../../../constants/currencies';

import { getCurrentLanguageTexts } from '../../../../constants/seasonPassPersonalInfoLangs';
import { Tooltip } from '../../../../components/UI/Tooltip/Tooltip';

const MySwal = withReactContent(Swal);

export const EditMembershipCardInfoModal = ({ membershipCard, editMembeshipCardInfo, eventInfo }) => {
    const txt = getCurrentLanguageTexts();;

    const [name, setName] = useState(membershipCard.customerName);
    const [selectedSeat, setSelectedSeat] = useState(null)
    const [selectable, setSelectable] = useState(membershipCard ? [getLabel(membershipCard)] : []);

    const allowSelectSeat = false;//membershipCard.firstPhaseMember && membershipCard.hasDefaultSeat;

    const saveNameHandler = () => {
        const request = {
            ticketId: membershipCard.id,
            fullName: name,

        }
        if (selectedSeat) {
            request.area = selectedSeat.labels.section.includes('-') ? selectedSeat.labels.section.split('-')[0] : selectedSeat.labels.section;
            request.side = selectedSeat.labels.section.includes('-') ? selectedSeat.labels.section.split('-')[1] : null;
            request.row = selectedSeat.labels.parent;
            request.seat = selectedSeat.labels.own;
        }

        editMembeshipCardInfo(request);

        MySwal.close();
    };

    const restoreToDefaultHandler = () => {
        const request = {
            ticketId: membershipCard.id,
            fullName: membershipCard.customerName,
            restoreToDefaultSeat: true,
        }

        editMembeshipCardInfo(request);

        MySwal.close();
    };

    const selected = (selectedSeats) => {
        setSelectedSeat(selectedSeats);
    }

    const deselected = (selectedSeats) => {
        setSelectedSeat(null);
    }

    const onChartRendered = (chart) => {

        if (membershipCard) {
            chart.trySelectObjects([getLabel(membershipCard)]).then(async r => {
            }, async r => {
                const label = getLabel(membershipCard);
                chart.pulse([label]);
                setSelectedSeat({
                    label: label,
                    labels: {
                        section: membershipCard.area + (membershipCard.side ? `-${membershipCard.side}` : ''),
                        parent: membershipCard.row,
                        own: membershipCard.seat,
                    }
                })
            });

            chart.zoomToObjects([getLabel(membershipCard)]);
        }
    }

    return <>
        <div>
            <span className='swal2-label'>{txt.EditMembershipCardInfoModalTxt['placeholder']}</span>
        </div>
        {membershipCard.templateId === 1006 || membershipCard.templateId === 1007
        || membershipCard.templateId === 5101 || membershipCard.templateId === 5102 ? (
          <input
            value={name}
            placeholder={txt.EditMembershipCardInfoModalTxt['placeholder']}
            className="modal-input"
            onChange={e => setName(e.target.value)} />
        ) : (
          <p>{membershipCard.customerName}</p>
        )}


        {allowSelectSeat &&
            <>
                <div className='swal2-flex'>

                    <span className='swal2-label'><b>{txt.EditMembershipCardInfoModalTxt['selectedSeat']}: {(selectedSeat && selectedSeat.label) || ''}</b></span>

                    <Tooltip description={txt.EditMembershipCardInfoModalTxt['restoreButtonDescription']}>
                        <IconButton className='btn-default IconButton' onClick={restoreToDefaultHandler} iconName="history" />
                    </Tooltip>

                </div>

                <SeatsioSeatingChart
                    maxSelectedObjects={1}
                    pricing={eventInfo.ticketTemplates.map(t => {
                        return {
                            category: t.categoryKey,
                            price: t.price
                        }
                    })}
                    session="start"
                    selectableObjects={selectable}
                    priceFormatter={(price) => `${price} ${getCurrencieLabel(eventInfo.currencyId)}`}
                    onObjectSelected={selected}
                    onObjectDeselected={deselected}
                    workspaceKey={eventInfo.seatsIoWorkspaceId}
                    event={eventInfo.seatsIoEventId}
                    onChartRendered={onChartRendered}
                    objectWithoutPricingSelectable={false}
                    region="eu"
                />
            </>
        }

        <section className='swal2-actions'>

            <button className="swal2-cancel swal2-styled"
                onClick={() => MySwal.close()} >
                {txt.generalTxt['cancel']}
            </button>
            <button disabled={name ? undefined : true} className="swal2-confirm swal2-styled"
                onClick={saveNameHandler}>
                {txt.generalTxt['save']}
            </button>

        </section>
    </>
}


function getLabel(ticket) {
    if (!ticket.seat) {
        return '';
    }

    return ticket.area + (!!ticket.side ? `-${ticket.side}` : '') + `-${ticket.row}-${ticket.seat}`
}