export const periods = [
  { value: 0, label: '24 hours' },
  { value: 1, label: 'Past week' },
  { value: 2, label: 'Past month' },
  { value: 3, label: 'Past year' },
  { value: 4, label: 'All' },
];

export const emailsStatuses = [
  { value: 1, label: 'delivered' },
  { value: 2, label: 'open' },
  { value: 3, label: 'bounce' },
  { value: 4, label: 'processed' },
  { value: 5, label: 'dropped' },
  { value: 6, label: 'deferred' },
  { value: 7, label: 'spamreport' },
  { value: 8, label: 'null' },
];

export const emailsTypes = [
  { value: 2, label: 'Solved' },
  { value: 3, label: 'Unsolved' },
];

export const bulkActions = {
  resend: 1,
  solve: 2,
  unsolve: 3,
};
