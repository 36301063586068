import React, { useEffect, useMemo, useState, useCallback } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import queryString from "query-string";
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import Select from "react-select";
import axios from "../../axios-instance";
import "../Transactions/Transactions.sass";
import "./MembershipManagment.sass";
import NewLoader from "../../components/NewLoader/NewLoader";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import { getHeaders } from "../../helpers/getHeaders";
import Container from "../../components/UI/Container/Container";
import { StatusLabel } from "../../components/Transactions/StatusLabel/StatusLabel";
import { IconButton } from "../../components/UI/IconButton/IconButton";
import { MembershipManagmentAction } from "../../components/MembershipManagment/MembershipManagmentAction/MembershipManagmentAction";
import Warning from "../../images/warning.svg";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import ClipboardJS from "clipboard";
import debounce from "lodash.debounce";
import Icon from "../../components/UI/Icon/Icon";
import { loadFile } from "../../helpers/loadFile";
import ls from 'localstorage-slim';
import { executeAsyncWithModal } from "../../helpers/modal";
import PleaseWaitModal from "../BarCodes/modals/PleaseWaitModal";
import { getCustomURLs } from "../../helpers/getCustomURLs";
import { PointsHistoryModal } from "./PointsHistoryModal/PointsHistoryModal";
import { ChangePointsModal } from "./ChangePointsModal/ChangePointsModal";

const MySwal = withReactContent(swal);

export const membershipStatuses = [
  { value: 1, label: "Created" },
  { value: 2, label: "Pending to Approve" },
  { value: 3, label: "Approved" },
  { value: 4, label: "Restricted" },
];

const MembershipManagment = (params) => {
  const token = params.token;
  const pageAction = queryString.parse(params.location.search).action;
  const pageActionFrom = queryString.parse(params.location.search).actionFrom;
  const pageActionTo = queryString.parse(params.location.search).actionTo;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchProfileStatus, setSearchProfileStatus] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [requestedSearchQuery, setRequestedSearchQuery] = useState("");
  const [sortingType, setSortingType] = useState(3);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [totalItems, setTotalItems] = useState(0);

  const { PUBLIC_URL } = getCustomURLs();

  const ReactTableFixedColumns = useMemo(
    () => withFixedColumns(ReactTable),
    []
  );

  const showLinkCopied = (link) => {
    ClipboardJS.copy(link);

    toast.dismiss(257);
    toast.success("Link was copied to clipboard", {
      toastId: 257,
    });
  };

  const copyMemberPageLink = (data) => {
    showLinkCopied(
      `${PUBLIC_URL}/member?token=${data.accessToken}`
    );
  };

  const editMemberData = (data) => {
    window.open(
      `${PUBLIC_URL}/member?token=${data.accessToken}&action=edit`,
      "_blank"
    );
  };

  const manageMemberSP = (data) => {
    window.open(
      `${PUBLIC_URL}/member?token=${data.accessToken}&action=selling`,
      "_blank"
    );
  };

  const getData = async () => {
    setLoading(true);
    const { data } = await axios.post(
      "/MembershipManagment/search",
      {
        searchQuery: requestedSearchQuery,
        statuses:
          searchProfileStatus == null ? [] : [searchProfileStatus.value],
        sortingType: sortingType,
        pageSize,
        page: currentPageNumber,
      },
      {
        headers: getHeaders(token),
      }
    );

    setLoading(false);

    setData(
      data.list.map((item, i) => {
        const { customer } = item;
        const { id, ...otherFields } = customer;

        return { ...item, row: i + 1, ...otherFields };
      })
    );

    setTotalItems(data.total);

    if (
      pageAction == "reject" &&
      !(ls.get("rejectStarted") === true)
    ) {
      ls.set("rejectStarted", true);

      for (var i = 1 * pageActionFrom - 1; i <= 1 * pageActionTo - 1; i++) {
        var item = data[i];

        if (
          data[i].status == 1 &&
          !ls.get("rejected_id_" + item.id)
        ) {
          const { data: res } = await axios.put(
            "/MembershipManagment/reject-membership-request",
            {
              memberId: item.id,
            },
            {
              headers: getHeaders(token),
            }
          );

          if (res) {
            ls.set("rejected_id_" + item.id, true);
          }
        }
      }
    }

    if (pageAction == "rejectReset") {
      ls.set("rejectStarted", false);
      var keys = Object.keys(localStorage),
        j = keys.length;

      while (j--) {
        if (keys[j].startsWith("rejected_id_")) {
          ls.remove(keys[j]);
        }
      }
    }

    setLoading(false);
  };

  const showApproveModal = (data) => {
    MySwal.fire({
      imageUrl: Warning,
      title: "Confirm Approval of the Request",
      text: "Please note that this is an irreversible action",
      cancelButtonText: "Cancel",
      confirmButtonText: "Approve",
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        approveRequest(data);
      }
    });
  };

  const showRejectModal = (data) => {
    MySwal.fire({
      imageUrl: Warning,
      title: "Confirm Reject of the Request",
      text: "Please note that this is an irreversible action",
      cancelButtonText: "Cancel",
      confirmButtonText: "Reject",
      allowEscapeKey: false,
      customClass: "seasonpass__modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        rejectRequest(data);
      }
    });
  };

  const changeSortingType = () => {
    setSortingType((curr) => (curr === 2 ? 3 : 2));
    // getData();
  };

  const changeSearchProfileStatusHandler = (item) => {
    setSearchProfileStatus(item);
    setCurrentPageNumber(0);
    // getData();
  };

  const handleDebonceFn = (value) => {
      setRequestedSearchQuery(value);
      setCurrentPageNumber(0);
    };
  
  const debounceFn = useCallback(debounce(handleDebonceFn, 1500), []);

  const changeSearchInputHandler = (e) => {
    setSearchQuery(e.target.value);
    debounceFn(e.target.value);
    // getData();
  };

  const approveRequest = async (data) => {
    const { data: res } = await axios.put(
      "/MembershipManagment/approve-membership-request",
      {
        memberId: data.id,
      },
      {
        headers: getHeaders(token),
      }
    );

    if (res) {
      toast.success("Successfully approved");
      getData();
    }
  };

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      height: "46px",
      "min-height": "46px",
      cursor: "pointer",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#ABABAB",
    }),
  };

  const rejectRequest = async (data) => {
    const { data: res } = await axios.put(
      "/MembershipManagment/reject-membership-request",
      {
        memberId: data.id,
      },
      {
        headers: getHeaders(token),
      }
    );
    if (res) {
      toast.success("Successfully rejected");
      getData();
    }
  };

  const showChangePointsModal = (id, email, points) => {
    MySwal.fire({
      html: <ChangePointsModal
        id={id}
        email={email}
        token={token}
        getData={getData}
        points={points}
      />,
      customClass: "swal2-modal",
      showConfirmButton: false,
    })
  };

  const getPointsHistory = async (memberId) => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `/MembershipManagment/${memberId}`, { headers: getHeaders(token) }
      );

      MySwal.fire({
        html: <PointsHistoryModal
          pointsHistory={data.pointsHistory}
          email={data.email}
          points={data.points}
        />,
        width: "560px",
        showConfirmButton: true,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Change points",
      }).then((result) => {
        if (result.value) {
          showChangePointsModal(memberId, data.email, data.points);
        }
      });
      
    } catch (error) {
      toast.error("Something went wrong")
    } finally {
      setLoading(false)
    }
  }

  const resetPoints = async () => {
    setLoading(true);
    try {
      await axios.post(
        `/MembershipManagment/ClearPoints`, null, { headers: getHeaders(token) }
      );

      toast.success("Points reset successfully")

      getData();
    } catch (error) {
      setLoading(false)
      toast.error("Something went wrong")
    }
  }

  const showResetPointsModal = () => {
    MySwal.fire({
      title: "Are you sure you want to reset points?",
      customClass: "swal2-modal",
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: "Reset"
    }).then((result) => {
      if (result.value) {
        resetPoints();
      }
    });
  }

  const handleCurrentPageChanged = (value) => {
    setCurrentPageNumber(value);
  };

  const handlePageSizeChanged = (value) => {
    setPageSize(value);
    setCurrentPageNumber(0);
  };

  useEffect(() => {
    getData();
  }, [requestedSearchQuery, searchProfileStatus, sortingType, pageSize, currentPageNumber]);

  let emptyStateText = "There are no data";

  const getLabelStyle = (state, rowInfo, column) => ({
    style: {
      marginTop: "6px",
    },
  });

  const getFlexStyle = () => ({
    style: {
      padding: "3px 20px 0",
      display: "flex",
      alignItems: "center",
    },
  });

  const getActionStyle = (e) => ({
    style: {
      padding: "3px 5px 0",
      display: "flex",
      alignItems: "center",
    },
    onClick: (e) => {
      e.stopPropagation()
    },
  });

  const downloadDataAsFile = (data, name) => {
    if (data) {
      loadFile(data, name);

      toast.success("File uploaded successfully");
    } else {
      toast.error("Failed to retrieve data");
    }
  };

  const downloadReportHandler = async () => {
    try {

      const user = ls.get("user");

      const request = () =>
        axios.get(`/MembershipManagment/MembershipReport`, {
          headers: getHeaders(user.token),
        });

      const { data } = await executeAsyncWithModal(
        request,
        <PleaseWaitModal message="Downloading file…" />
      );

      downloadDataAsFile(data, `Membership Report.csv`);
    } catch (e) {
      toast.error("File was not downloaded");
      console.log(e);
    }
  };

  /*if (!data.length && !loading) {
    return <Page404 />;
  }*/

  const columns = [
    {
      Header: "First Name",
      accessor: "firstName",
      resizable: false,
      width: 140,
      getProps: getLabelStyle,
      Cell: ({ row: { _original: { firstName }}}) => (
        <span title={firstName}>{firstName}</span>
      ),
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      resizable: false,
      width: 140,
      getProps: getLabelStyle,
      Cell: ({ row: { _original: { lastName }}}) => (
        <span title={lastName}>{lastName}</span>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      resizable: false,
      width: 220,
      getProps: getLabelStyle,
      Cell: ({ row: { _original: { email }}}) => (
        <span title={email}>{email}</span>
      ),
    },
    {
      Header: () => (
        <div className="payment-status" onClick={changeSortingType}>
          <span>Status</span>
          <Icon name="arrow-up-down" />
        </div>
      ),
      resizable: false,
      getProps: getFlexStyle,
      width: 150,
      Cell: ({
        row: {
          _original: { status },
        },
      }) => {
        return (
          <StatusLabel statusValue={status} statuses={membershipStatuses} />
        );
      },
    },
    {
      Header: "Points",
      accessor: "points",
      resizable: false,
      width: 120,
      getProps: getLabelStyle,
    },
    {
      Header: "Phone Number",
      accessor: "phoneNumber",
      resizable: false,
      width: 180,
      Cell: ({ row: { _original: { phoneNumber }}}) => (
        <span title={phoneNumber}>{phoneNumber}</span>
      ),
      getProps: getLabelStyle,
    },
    {
      Header: "City",
      accessor: "city",
      resizable: false,
      width: 120,
      Cell: ({ row: { _original: { city }}}) => (
        <span title={city}>{city}</span>
      ),
      getProps: getLabelStyle,
    },
    {
      Header: "Address",
      accessor: "physicalAddress",
      resizable: false,
      width: 200,
      Cell: ({ row: { _original: { physicalAddress }}}) => (
        <span title={physicalAddress}>{physicalAddress}</span>
      ),
      getProps: getLabelStyle,
    },
    {
      Header: "Postal Code",
      accessor: "postalCode",
      resizable: false,
      width: 100,
      getProps: getLabelStyle,
    },
    {
      Cell: ({ row: { _original: { id, email, points }, _original } }) => (
        <MembershipManagmentAction
          data={_original}
          showApproveModal={showApproveModal}
          copyMemberPageLink={copyMemberPageLink}
          editMemberData={editMemberData}
          manageMemberSP={manageMemberSP}
          showRejectModal={showRejectModal}
          showChangePointsModal={() => showChangePointsModal(id, email, points)}
        />
      ),
      getProps: getActionStyle,
      width: 60,
      fixed: "right",
    },
  ];

  return (
    <Container className="membership-managment">
      <PageTitle>Membership</PageTitle>

      <div className="searchPanel">
        <Select
          isClearable
          options={membershipStatuses}
          placeholder="Select status"
          name="searchProfileStatus"
          classNamePrefix="custom-select"
          value={searchProfileStatus}
          onChange={changeSearchProfileStatusHandler}
          className="seasonpass__select searchPanel__select"
          styles={customSelectStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#6071B5",
              primary25: "#F2F4FE",
              primary50: "#F2F4FE",
              primary75: "#F2F4FE",
            },
          })}
        />

        <input
          className="searchPanel__search input-behavior search-icon"
          type="text"
          placeholder="Search"
          value={searchQuery}
          onChange={changeSearchInputHandler}
        />

        <div className="membership-managment__buttons">
          <button className="btn-secondary membership-managment__reset-button admin"
           onClick={showResetPointsModal}>Reset Points</button>
          <button className="btn-primary-new admin"
           onClick={downloadReportHandler}>Download Report</button>
        </div>
      </div>

      {loading ? (
        <NewLoader />
      ) : data.length ? (
        <>
          <ReactTableFixedColumns
            data={data}
            columns={columns}
            manual
            showPagination={true}
            pageSizeOptions={[100, 200, 500, 1000, 100000]}
            minRows={0}
            page={currentPageNumber}
            pageSize={pageSize}
            pages={Math.ceil(totalItems / pageSize)}
            onPageChange={handleCurrentPageChanged}
            onPageSizeChange={handlePageSizeChanged}
            sortable={false}
            getTrProps={(state, { original }) => ({
              style: {
                alignItems: "stretch",
              },
              onClick: () => {
                getPointsHistory(original.id, original.email);
              },
            })}
          // getTableProps={() => ({
          //   onScroll: handleTransactionsTableScroll,
          // })}
          />
        </>
      ) : (
        <div className="events__empty-state">
          <PageTitle>{emptyStateText}</PageTitle>
          <img src="/images/img-empty-events.svg" alt="Empty membership" />
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = ({ auth }) => ({
  auth,
  token: auth.user.token,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MembershipManagment)
);
