import React from "react";

import CheckBox from "../../UI/Checkbox/CheckBox";
import { getSeatDescription } from "../../../helpers/getSeatDescription";

const isChecked = (checkedSeats, id) => checkedSeats.includes(id);

export const SeatsDescriptionModal = ({ seats, checkedSeats, handleCheck, currency }) => {
  return seats.map((seat) => (
    <label key={seat.id} className="checkbox-label">

      <CheckBox
        id={seat.id}
        name={seat.id}
        related={seat.id}
        checked={isChecked(checkedSeats, seat.id)}
        onChange={handleCheck}
      />

      <div>{getSeatDescription(seat, currency)}</div>

    </label>
  ));
};
