import * as actionTypes from "../actionTypes/emailsTypes";

const initialState = {
  emailsList: [],
  isEmailsLoading: false,
  isProblematicSolving: false,
  currentEmail: {
    subject: "",
    status: "",
    body: "",
  },
  isEmailLoading: false,
  problematicEmailsCount: null,
};

const emails = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EMAILS:
      return { ...state, isEmailsLoading: true };

    case actionTypes.GET_EMAILS_SUCCESS:
      return { ...state, isEmailsLoading: false, emailsList: action.payload };

    case actionTypes.GET_EMAILS_FAIL:
      return { ...state, isEmailsLoading: false };

    case actionTypes.GET_EMAIL:
      return { ...state, isEmailLoading: true };

    case actionTypes.GET_EMAIL_SUCCESS:
      return { ...state, isEmailLoading: false, currentEmail: action.payload };

    case actionTypes.GET_EMAIL_FAIL:
      return { ...state, isEmailLoading: false };

    case actionTypes.RESEND_EMAIL:
      return { ...state, isEmailLoading: true };

    case actionTypes.RESEND_EMAIL_SUCCESS:
      return { ...state, isEmailLoading: false };

    case actionTypes.RESEND_EMAIL_FAIL:
      return { ...state, isEmailLoading: false };

    case actionTypes.GET_PROBLEMATIC_EMAILS_COUNT_SUCCESS:
      return { ...state, problematicEmailsCount: action.payload };

    case actionTypes.UPDATE_PROBLEMATIC_EMAIL:
      return { ...state, isProblematicSolving: true };

    case actionTypes.UPDATE_PROBLEMATIC_EMAIL_SUCCESS:
      return { ...state, isProblematicSolving: false };

    case actionTypes.UPDATE_PROBLEMATIC_EMAIL_FAIL:
      return { ...state, isProblematicSolving: false };

    default:
      return state;
  }
}

export default emails;
