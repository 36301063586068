import React, { Component } from "react";
import ROUTES from "../../constants/routes";
import { NavLink, withRouter } from "react-router-dom";

import "./NavigationTabs.sass";

class NavigationTabs extends Component {

    goToPage = (route) => {
        return route.replace(":accessToken", this.props.match.params.accessToken);
    };

    render() {
        const links = [
            {
                title: 'Info',
                route: ROUTES.MEMBER_NEW_INFO,
            },
            {
                title: 'Memberships',
                route: ROUTES.MEMBER_NEW_CARDS,
            },
            {
                title: 'Tickets',
                route: ROUTES.MEMBER_NEW_EVENTS,
            },
        ];

        return (
            <nav className="nav-tabs">
                <div className="nav-tabs__container nav-tabs__container--small">
                    {links.map(link => {
                        return (
                            <NavLink
                                className="nav-tabs__item"
                                to={this.goToPage(link.route)}
                                key={link.route}
                            >
                                <span>{link.title}</span>
                            </NavLink>
                        )
                    })}
                </div>
            </nav>
        );
    }
};

export default withRouter(NavigationTabs);

